import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function InterviewList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: "select", sortable: false },
    { key: 'title', sortable: true },
    { key: "join_url", sortable: false, label: "Join URL" },
    { key: 'start_at', sortable: true },
    { key: 'candidate_name', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalInterviewList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const interviewStatus = ref([])
  const statusFilter = ref(null)
  const createdByFilter = ref(null)
  const interviewDateFilter = ref(null)
  const interviewByFilter = ref(null)
  const interviewMetaData = ref([]);
  

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInterviewList.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery,statusFilter,createdByFilter,interviewDateFilter,interviewByFilter], () => {
    refetchData()
  })

  const fetchInterviewList = (ctx, callback) => {
    store
      .dispatch('app-interviews/fetchInterviews', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        statusFilter: statusFilter.value,
        createdByFilter: createdByFilter.value,
        interviewDateFilter: interviewDateFilter.value,
        interviewByFilter: interviewByFilter.value,
      })
      .then(response => {
        const interviews = response.data.data
        const { total } = response.data.meta
        interviewStatus.value = response.data.defaultInterviewStatus

        callback(interviews)
        totalInterviewList.value = total
        interviewMetaData.value = interviews;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching interviews',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchInterviewList,
    tableColumns,
    perPage,
    currentPage,
    totalInterviewList,
    dataMeta,
    interviewMetaData,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,
    interviewStatus,
    statusFilter,
    createdByFilter,
    interviewDateFilter,
    interviewByFilter,
  }
}
