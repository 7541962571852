<template>
  <div>
    <interview-add
      :interview-id.sync="interviewId"
      :interview-data.sync="interviewData"
      :is-add-new-interview-sidebar-active.sync="isAddNewInterviewSidebarActive"
      :candidate-change-flag.sync="candidateChangeFlag"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <b-card-actions
      title="Filters"
      no-body
      class="mb-0"
      actionRefresh
      actionCollapse
      @refresh="triggerRefresh"
    >
      <interview-list-filters
        :status-filter.sync="statusFilter"
        :status-options="interviewStatus"
        :created-by-filter.sync="createdByFilter"
        :employee-options="createdByFilterOptions"
        :interview-date-filter.sync="interviewDateFilter"
        :interview-by-filter.sync="interviewByFilter"
      />
    </b-card-actions>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              class="mr-2"
              v-model="allSelected"
              v-if="interviewMetaData.length && $can('Zircly Admin')"
            >
            </b-form-checkbox>

            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              class="d-flex align-items-center justify-content-end"
              v-if="selectedItems.length == 0"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search Title"
                />
              </b-input-group>

              <b-button
                v-if="$can('interview_create')"
                variant="primary"
                @click="addInterviewNow()"
              >
                <span class="text-nowrap">Add Interview</span>
              </b-button>
            </div>
            <b-button
              v-else-if="$can('interview_delete')"
              class="float-right"
              variant="danger"
              :disabled="selectedItems.length == 0 || isBusy"
              @click="confirmDeleteMultipleRecords"
            >
              <b-spinner v-if="isBusy" small class="mr-50"></b-spinner>
              <span class="text-nowrap"
                >Delete {{ selectedItems.length }} Interview</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedItems"
        name="selectedItems"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchInterviewList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>

          <!-- Column: User -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('interview_show')"
                :to="{
                  name: 'view-interview',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.title"
              >
                {{ data.item.title | str_limit(40) }}
              </b-link>
              <span v-else v-b-tooltip.hover.v-default :title="data.item.title">
                {{ data.item.title | str_limit(40) }}
              </span>
            </b-media>
          </template>

          <template #cell(join_url)="data">
            <div v-if="data.item.join_url" class="">
              <b-button
                variant="info"
                target="_blank"
                size="sm"
                @click="open_join_url(data.item.join_url, data.item.hashid)"
              >
                Join Interview
              </b-button>
              <b-button
                type="button"
                size="sm"
                v-clipboard:copy="joinUrl(data.item.join_url, data.item.hashid)"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                variant="flat-secondary"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </div>
          </template>

          <!-- Column: User -->
          <template #cell(candidate_name)="data">
            <span v-b-tooltip.hover.v-default :title="data.item.candidate_name">
              {{ data.item.candidate_name | str_limit(15) }}
            </span>
          </template>
          <!-- Column: User -->
          <template #cell(start_at)="data">
            {{ data.item.start_at | formatDateTime }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <feather-icon
              @click="editRecord(data)"
              v-if="$can('interview_edit')"
              v-b-tooltip.hover
              title="Edit"
              icon="EditIcon"
              size="16"
              class="mr-1 cursor-pointer"
            />
            <feather-icon
              @click="viewRecord(data)"
              v-if="$can('interview_show')"
              v-b-tooltip.hover
              title="View"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer"
            />

            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                class="btn-sm p-0"
                @click="openWindow(data.item.resume_file_path)"
                v-if="$can('interview_show') && data.item.resume_file_path"
              >
                <feather-icon icon="FileTextIcon" size="13" class="mr-50" />
                <span>View Resume</span>
              </b-dropdown-item>
              <b-dropdown-item
                class="btn-sm p-0"
                v-if="data.item.auth_user_can_add_feedback"
                @click="writeFeedback(data)"
              >
                <feather-icon
                  icon="MessageSquareIcon"
                  size="13"
                  class="mr-50"
                />
                <span>Write Feedback</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(data)"
                v-if="$can('interview_delete')"
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              v-if="selectedItems.length == 0"
              :total-rows="totalInterviewList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

            <b-button
              v-else-if="$can('interview_delete')"
              class="float-right"
              variant="danger"
              :disabled="selectedItems.length == 0 || isBusy"
              @click="confirmDeleteMultipleRecords"
            >
              <b-spinner v-if="isBusy" small class="mr-50"></b-spinner>
              <span class="text-nowrap"
                >Delete {{ selectedItems.length }} Interview</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
} from "bootstrap-vue";
// import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import interviewStoreModule from "../interviewStoreModule";
import useInterviewList from "./useInterviewList";
import InterviewAdd from "./InterviewAdd.vue";
import InterviewListFilters from "./InterviewListFilters.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import BCardActionsContainer from "@core/components/b-card-actions/BCardActionsContainer.vue";
import moment from "moment";

export default {
  components: {
    InterviewListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,

    // vSelect,
    InterviewAdd,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BCardActions,
    BCardActionsContainer,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const INTERVIEW_APP_STORE_MODULE_NAME = "app-interviews";

    var isDeleteMultipleChecked = ref(false);
    var selectedItems = ref([]);
    var itemDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(INTERVIEW_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        INTERVIEW_APP_STORE_MODULE_NAME,
        interviewStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INTERVIEW_APP_STORE_MODULE_NAME))
        store.unregisterModule(INTERVIEW_APP_STORE_MODULE_NAME);
    });

    const isAddNewInterviewSidebarActive = ref(false);

    const {
      fetchInterviewList,
      tableColumns,
      perPage,
      currentPage,
      totalInterviewList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      interviewStatus,
      statusFilter,
      createdByFilter,
      interviewDateFilter,
      interviewByFilter,
      interviewMetaData,
    } = useInterviewList();

    return {
      // Sidebar
      isAddNewInterviewSidebarActive,
      fetchInterviewList,
      tableColumns,
      perPage,
      currentPage,
      totalInterviewList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      interviewStatus,
      createdByFilter,
      interviewDateFilter,
      interviewByFilter,
      interviewMetaData,
      isDeleteMultipleChecked,
      itemDeleteCount,
      selectedItems,
    };
  },
  data() {
    return {
      isBusy: false,
      interviewId: "",
      interviewData: {
        title: "",
        agenta: "",
        start_at: moment().format("YYYY-MM-DD HH:mm"),
        end_at: moment().add(1, "hour").format("YYYY-MM-DD HH:mm"),
        candidate_id: "",
        interviewers: "",
        meeting_details: "",
        start_date: "",
      },
      candidateData: [],
      candidateChangeFlag: false,
      createdByFilterOptions: [],
      allSelected: false,
    };
  },
  created() {
    const self = this;
    // if (!this.$can("Zircly Admin")) {
    //   this.tableColumns = this.tableColumns.filter(
    //     (item) => item.key != "select"
    //   );
    // }
    this.$store
      .dispatch("app-interviews/fetchCandidates")
      .then((res) => {
        self.candidateData = res.data.data;
      })
      .catch((err) => {
        console.error(err);
      });

    this.$http
      .get("/list/employees")
      .then((res) => {
        this.createdByFilterOptions = res.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    triggerRefresh() {
      this.roleFilter = "";
      this.createdByFilter = "";
      this.interviewDateFilter = "";
      this.showLoading = false;
    },
    joinUrl(url, hash_id) {
      let new_url = this.$router.resolve({
        path: "/join",
        query: { type: "interview", hash_id: hash_id, url: url },
      });
      return window.location.origin + new_url.href;
    },
    open_join_url(url, hash_id) {
      let data = {};
      data.url = url;

      let routeData = this.$router.resolve({
        path: "/join",
        query: { type: "interview", hash_id: hash_id, url: url },
      });
      return window.open(routeData.href, "_blank");
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Text copied",
          icon: "BellIcon",
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy texts!",
          icon: "BellIcon",
        },
      });
    },
    addInterviewNow() {
      this.interviewId = "";
      this.interviewData = {
        title: "",
        agenta: "",
        start_at: moment().format("YYYY-MM-DD HH:mm"),
        end_at: moment().add(1, "hour").format("YYYY-MM-DD HH:mm"),
        candidate_id: "",
        interviewers: "",
        meeting_details: "",
      };
      this.isAddNewInterviewSidebarActive = true;
      this.candidateChangeFlag = false;
    },
    editRecord(data) {
      const self = this;
      this.$store
        .dispatch("app-interviews/fetchInterview", data.item.hashid)
        .then((res) => {
          self.interviewId = data.item.hashid;
          self.interviewData = res.data.data;
          self.isAddNewInterviewSidebarActive = true;
          self.candidateChangeFlag = true;

          self.interviewData.start_time = this.getTimeMap(
            new Date(this.interviewData.start_at).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })
          );
          self.interviewData.end_time = this.getTimeMap(
            new Date(this.interviewData.end_at).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })
          );
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTimeMap(temp) {
      return {
        hh:
          temp.split(":")[0].length == 1
            ? "0" + temp.split(":")[0]
            : temp.split(":")[0],
        mm: temp.split(":")[1].split(" ")[0],
        A: temp.split(":")[1].split(" ")[1],
      };
    },
    viewRecord(data) {
      this.$router.push(`/view/interview/${data.item.hashid}`).catch(() => {});
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedItems.length} Interview, This cannot be undone`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isBusy = true;
          this.$store
            .dispatch(
              "app-interviews/removeMultipleInterview",
              this.selectedItems
            )
            .then((res) => {
              this.isDeleteMultipleChecked = false;
              this.selectedItems = [];
              if (res.data.success) {
                this.refetchData();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Deleted Successfully",
                    icon: "BellIcon",
                    variant: "success",
                    text: res.data.message,
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Interview Deletion failed",
                    icon: "BellIcon",
                    variant: "danger",
                    text: res.data.message,
                  },
                });
              }
            })
            .catch((err) => console.log(err));
          this.isBusy = false;
        }
      });
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.title}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    deleteRecord(id) {
      const self = this;
      this.isBusy = true;
      this.$store
        .dispatch("app-interviews/removeInterview", id)
        .then((res) => {
          if (res.data.success) {
            self.refetchData();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Interview Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Interview deleted successfully",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Interview Deletion failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
      this.isBusy = false;
    },
    openWindow(link) {
      window.open(link);
    },
    writeFeedback(data) {
      this.$router.push({
        path: `/view/interview/${data.item.hashid}`,
        query: { isViewInterviewSidebarActive: true },
      });
    },
  },
  watch: {
    allSelected(val) {
      this.selectedItems = [];
      if (val) {
        this.interviewMetaData.forEach((item) => {
          this.selectedItems.push(item.hashid);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
